import {
  buildInternalRoute,
  IAllGraphqlModules,
  IAllGraphqlPages,
  IGraphqlAllRegionCountryLanguageAssignment,
  IGraphqlLocaleIdentifier,
  IGraphqlM0147,
  IGraphqlPage,
  IGraphqlThemeNames,
  TInternalRoutePageTree,
} from '@bemer/base';
import { BemPage } from '@bemer/ui-library';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import {
  AppCenteredSpinner,
  AppHelmet,
  AppModule,
  AppPageWrapper,
} from '../components';
import { IPageContext } from '../interfaces';

interface IPropsPageTemplate {
  data: {
    page: IGraphqlPage;
    pageForAllLocaleIdentifiers: IAllGraphqlPages;
    pathNames: TInternalRoutePageTree;
    theme: IGraphqlThemeNames;
    isSubNavigationHidden?: boolean;
    modalModules: IAllGraphqlModules;
    allRegionCountryLanguageAssignments: IGraphqlAllRegionCountryLanguageAssignment;
  };
  pageContext: IPageContext;
}

const PageTemplate = ({
  data,
  pageContext,
}: IPropsPageTemplate): JSX.Element | null => {
  if (!data || !data.page || !data.allRegionCountryLanguageAssignments) {
    return null;
  }

  // possible TODO: make this a constant to import?
  const allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[] = [];
  data.allRegionCountryLanguageAssignments.edges.map((region) =>
    region.node.countries.map((country) =>
      country.localeIdentifiers.map((localeIdentifier) =>
        allActiveLocaleIdentifiers.push(localeIdentifier)
      )
    )
  );

  const { modalModules } = data;
  // If the current page is a page wrapper, build just a page with a title and a spinner and use
  // gatsby's navigate to go to the given redirectPage.
  if (data.page.isWrapperPage && data.page.redirectPage?.slug?.current) {
    if (typeof window !== 'undefined') {
      // In case we are not on a RegionMaster page, we need to set the current locale
      // from the pageContext as locale we want to generate internal routes of, and set
      // it as the locale we want to redirect to. (if on a RegionMaster page, the locale
      // will be the same, as in data.page.redirectPage, so no additional check is required.)
      navigate(
        buildInternalRoute(data.page.redirectPage, pageContext.localeIdentifier)
      );
    }
    return (
      <>
        <AppHelmet
          title={data.page.title}
          seo={data.page.seo}
          pageContext={pageContext}
          allActiveLocaleIdentifiers={allActiveLocaleIdentifiers}
        />
        <AppCenteredSpinner />
      </>
    );
  }
  const codeInjectionModules = data.page.modules
    .filter((module) => module.module[0]?._type === 'm0147')
    .map((module) => module.module[0] as IGraphqlM0147);

  const modules = data.page.modules.filter(
    (module) => module.module[0]?._type !== 'm0147'
  );

  return (
    <AppPageWrapper
      pageContext={pageContext}
      allActiveLocaleIdentifiers={allActiveLocaleIdentifiers}
      pathNames={data.pathNames}
      themes={data.theme}
      isSubNavigationHidden={data.page.isSubNavigationHidden}
      pageForAllLocaleIdentifiers={data.pageForAllLocaleIdentifiers.edges}
      allRegionCountryLanguageAssignments={
        data.allRegionCountryLanguageAssignments
      }
    >
      <AppHelmet
        codeInjectionModules={codeInjectionModules}
        title={data.page.title}
        pathNames={data.pathNames}
        allActiveLocaleIdentifiers={allActiveLocaleIdentifiers}
        pageContext={pageContext}
        seo={data.page.seo}
        pageForAllLocaleIdentifiers={data.pageForAllLocaleIdentifiers.edges}
      />
      <BemPage>
        {modules.map(
          (module, index: number) =>
            module && (
              <AppModule
                key={module._id}
                modulePosition={index + 1}
                module={module}
              />
            )
        )}
        {modalModules.edges
          .filter(
            ({ node }) =>
              // filter by page language
              node.localeIdentifier.localeId ===
              data.page.localeIdentifier.localeId
          )
          .map(
            (module, index: number) =>
              module.node && (
                <AppModule
                  key={module.node._id}
                  modulePosition={index + 1}
                  module={module.node}
                />
              )
          )}
      </BemPage>
    </AppPageWrapper>
  );
};

const query = graphql`
  query ($_id: String!, $pageSearchString: String!) {
    allRegionCountryLanguageAssignments: allRegionCountryLanguageAssignment {
      edges {
        node {
          regionCode
          regionName
          countries {
            countryCode
            countryName
            isRegionMaster
            localeIdentifiers {
              active
              default
              isRegionMasterPrimaryLanguage
              languageCode
              languageName
              localeId
              country {
                countryCode
                countryName
                isRegionMaster
                region {
                  regionCode
                  regionName
                }
              }
            }
          }
        }
      }
    }
    page: sanityPage(_id: { eq: $_id }) {
      ...Page
      seo {
        description
        robots_noindex
        robots_nofollow
        og_title
        og_description
        og_type
        og_url
        og_siteName
        og_image
        og_imageAlt
        og_locale
        og_locale_alternate
        twitter_card
        twitter_site
        twitter_site_id
        twitter_creator
        twitter_description
        twitter_title
        twitter_image
        twitter_image_alt
        twitter_player
        twitter_player_width
        twitter_player_height
        twitter_player_stream
      }
    }
    modalModules: allSanityModule(filter: { isModalModule: { eq: true } }) {
      edges {
        node {
          ...Module
        }
      }
    }
    pageForAllLocaleIdentifiers: allSanityPage(
      filter: { _id: { glob: $pageSearchString } }
    ) {
      edges {
        node {
          ...AllLocaleIdentifierPage
        }
      }
    }
    theme: sanityPage(_id: { eq: $_id }) {
      theme
      parentPage {
        ... on SanityPage {
          theme
          parentPage {
            ... on SanityPage {
              theme
              parentPage {
                ... on SanityPage {
                  theme
                  parentPage {
                    ... on SanityPage {
                      theme
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    pathNames: sanityPage(_id: { eq: $_id }) {
      ...ParentPageTree
    }
  }
`;

export { PageTemplate as default, query };
